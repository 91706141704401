import React, { useState, useEffect } from 'react';
import { Grommet, Box } from 'grommet';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import patientService from '../services/patientService'; // Ensure this is the correct path to your patientService
import { Container } from '../components/dashboard/StyledComponents';
import LeftPanel from '../components/dashboard/LeftPanel';
import MainPanel from '../components/dashboard/MainPanel';
import Chat from '../components/dashboard/ChatContainer';
import LoadingSpinner from '../components/LoadingSpinner'; // Import the LoadingSpinner component

const Dashboard = () => {
    const [patients, setPatients] = useState([]);
    const [loading, setLoading] = useState(false); // Make sure loading state is here
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [inputText, setInputText] = useState('');
    const [charts, setCharts] = useState([]);
    const [selectedChartIndex, setSelectedChartIndex] = useState(0);
    const [isChartModalOpen, setIsChartModalOpen] = useState(false);
    const [activeContent, setActiveContent] = useState(null);
    const [dashboardCharts, setDashboardCharts] = useState(true);
    const [onDemandCharts, setOnDemandCharts] = useState(false);
    const { transcript, listening, resetTranscript } = useSpeechRecognition();

    // Define the fetchPatients function
    const fetchPatients = async () => {
        setLoading(true);
        try {
            const patientData = await patientService.listPatientsWithCharts();
            const mappedPatients = patientData.map(patient => ({
                key: patient.Key,
                name: patient.Record.name,
                birthDate: patient.Record.birthDate,
                gender: patient.Record.gender,
                address: patient.Record.address,
                email: patient.Record.communication.email,
                phone: patient.Record.communication.home,
                charts: patient.Record.charts || [],
                ...patient.Record
            }));
            setPatients(mappedPatients);
            if (mappedPatients.length > 0) {
                setSelectedPatient(mappedPatients[0]);
                setCharts(mappedPatients[0].charts || []);
            }
        } catch (error) {
            console.error('Failed to fetch patients:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPatients();
    }, []);

    useEffect(() => {
        if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
            console.warn("Browser doesn't support speech recognition.");
        }
    }, []);

    useEffect(() => {
        if (transcript) {
            if (transcript.toLowerCase().includes('stop listening')) {
                SpeechRecognition.stopListening();
                setInputText(''); // Clear the text area
            } else if (transcript.toLowerCase().includes('send')) {
                sendMessage(inputText);
            } else if (transcript.toLowerCase().includes('erase')) {
                setInputText(''); // Erase the text area
            } else {
                setInputText(transcript);
            }
        }
    }, [transcript]);

    const handleButtonClick = (content) => {
        setActiveContent(activeContent === content ? null : content);
    };

    const handleToggleButtonClick = (buttonType) => {
        if (buttonType === 'dashboard') {
            setDashboardCharts(!dashboardCharts);
        } else if (buttonType === 'onDemand') {
            setOnDemandCharts(!onDemandCharts);
        }
    };

    const handleIframeLoad = (event) => {
        const iframe = event.target;
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        if (iframeDocument) {
            const body = iframeDocument.body;
            const html = iframeDocument.documentElement;
            const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            iframe.style.height = `100%`;
        }
    };

    const sendMessage = async (prompt) => {
        if (!prompt.trim()) return;  // Check for empty input
        setLoading(true); // Set loading state
        const currentTime = new Date().toLocaleTimeString();

        try {
            let fullPrompt = `Patient = ${selectedPatient?.name}: \n${prompt}`; // Include active patient in the prompt

            setChatHistory(currentHistory => [
                ...currentHistory,
                { type: 'user', text: prompt, label: 'USER', timestamp: currentTime }
            ]);

            // Make API call
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ prompt: fullPrompt })
            };

            const response = await fetch('https://us-east4-agama-qrf-poc.cloudfunctions.net/generate-ai-charts', requestOptions);
            const data = await response.json(); // Assuming the response is JSON formatted
            let promptType = 'question';

            if (data && data.response) {
                if (data.promptType) {
                    promptType = data.promptType;
                }
                // Check if the response contains chart HTML code or plain text
                if (promptType === 'chart') {
                    let htmlContent = data.response.match(/<!DOCTYPE html>.*<\/html>/s);
                    let cleanedScript = htmlContent ? htmlContent[0] : '';
                    let newChartTitle = data.chartTitle;

                    let chartData = {
                        patientId: selectedPatient.key,
                        chartId: undefined,
                        chartTitle: newChartTitle,
                        chartDescription: 'dynamic',
                        chartHTML: cleanedScript,
                    };

                    await patientService.createChart(chartData);

                    // Re-fetch patients to get the updated charts
                    await fetchPatients();

                    setChatHistory(currentHistory => [
                        ...currentHistory,
                        { type: 'ai', text: 'Chart loaded successfully.', label: 'Agama AI', timestamp: new Date().toLocaleTimeString() }
                    ]);
                } else {
                    setChatHistory(currentHistory => [
                        ...currentHistory,
                        { type: 'ai', text: data.response, label: 'Agama AI', timestamp: new Date().toLocaleTimeString() }
                    ]);
                }
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setChatHistory(currentHistory => [
                ...currentHistory,
                { type: 'ai', text: 'Failed to fetch data', label: 'Agama AI', timestamp: new Date().toISOString() }
            ]);
        } finally {
            setLoading(false); // Clear loading state
        }

        setInputText('');
        resetTranscript(); // Reset transcript after sending the message
    };

    const handleVoiceCommand = () => {
        if (listening) {
            SpeechRecognition.stopListening();
            setInputText(''); // Clear the text area when listening stops
        } else {
            resetTranscript();
            setInputText(''); // Clear the text area when listening starts
            SpeechRecognition.startListening({ continuous: true });
        }
    };

    return (
        <Grommet>
            <Container direction="row">
                <Box direction="column" width="20%" height={{ min: "100vh" }}>
                    <LeftPanel
                        activeContent={activeContent}
                        setActiveContent={setActiveContent}
                        selectedPatient={selectedPatient}
                        setSelectedPatient={setSelectedPatient} // Pass setSelectedPatient to LeftPanel
                        handleButtonClick={handleButtonClick}
                        handleToggleButtonClick={handleToggleButtonClick}
                        dashboardCharts={dashboardCharts}
                        onDemandCharts={onDemandCharts}
                        chatHistory={chatHistory}
                        inputText={inputText}
                        setInputText={setInputText}
                        sendMessage={sendMessage}
                        handleVoiceCommand={handleVoiceCommand}
                        listening={listening}
                        loading={loading} // Pass loading state to LeftPanel
                        setLoading={setLoading} // Pass setLoading state to LeftPanel
                    />
                </Box>
                <MainPanel
                    selectedPatient={selectedPatient}
                    handleIframeLoad={handleIframeLoad}
                    dashboardCharts={dashboardCharts}
                    onDemandCharts={onDemandCharts} // Pass onDemandCharts to MainPanel
                    charts={charts} // Pass charts as a prop
                />
            </Container>
        </Grommet>
    );
};

export default Dashboard;

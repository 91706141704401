import React, { useState, useEffect } from 'react';
import { Box, FormField, TextInput, Select, CardHeader, Card, CardBody } from 'grommet';
import CommunicationField from './CommunicationField';
import AliasField from './AliasField';

const monthOptions = [
    { label: 'Jan.', value: '01' },
    { label: 'Feb.', value: '02' },
    { label: 'Mar.', value: '03' },
    { label: 'Apr.', value: '04' },
    { label: 'May.', value: '05' },
    { label: 'Jun.', value: '06' },
    { label: 'Jul.', value: '07' },
    { label: 'Aug.', value: '08' },
    { label: 'Sep.', value: '09' },
    { label: 'Oct.', value: '10' },
    { label: 'Nov.', value: '11' },
    { label: 'Dec.', value: '12' },
];

const PatientDemographics = ({ demographics, onUpdate }) => {
    const [addressParts, setAddressParts] = useState({
        street: '',
        city: '',
        state: '',
        zip: '',
    });

    const [birthdateParts, setBirthdateParts] = useState({
        month: '',
        day: '',
        year: '',
    });

    useEffect(() => {
        if (demographics.address) {
            const addressRegex = /^(.*?),\s*(.*?),\s*([A-Z]{2})\s*(\d{5}(?:-\d{4})?)$/;
            const match = demographics.address.match(addressRegex);
            if (match) {
                setAddressParts({
                    street: match[1] || '',
                    city: match[2] || '',
                    state: match[3] || '',
                    zip: match[4] || '',
                });
            }
        }

        if (demographics.birthDate) {
            const [month, day, year] = demographics.birthDate.split(' ');
            setBirthdateParts({
                month: monthOptions.find(option => option.label === month)?.value || '',
                day: day.replace(',', ''),
                year,
            });
        }
    }, [demographics]);

    const handleAddressChange = (field, value) => {
        const updatedAddressParts = { ...addressParts, [field]: value };
        setAddressParts(updatedAddressParts);
        const combinedAddress = `${updatedAddressParts.street}, ${updatedAddressParts.city}, ${updatedAddressParts.state}, ${updatedAddressParts.zip}`;
        onUpdate({ address: combinedAddress });
    };

    const handleBirthdateChange = (field, value) => {
        const updatedBirthdateParts = { ...birthdateParts, [field]: value };
        setBirthdateParts(updatedBirthdateParts);
        const monthLabel = monthOptions.find(option => option.value === updatedBirthdateParts.month)?.label;
        const combinedBirthdate = `${monthLabel} ${updatedBirthdateParts.day}, ${updatedBirthdateParts.year}`;
        onUpdate({ birthDate: combinedBirthdate });
    };

    const handleChange = (field, value) => {
        onUpdate({ [field]: value });
    };

    const handleCommunicationUpdate = (updatedCommunication) => {
        onUpdate({ communication: updatedCommunication });
    };

    const handleAliasesUpdate = (updatedAliases) => {
        onUpdate({ aliases: updatedAliases });
    };

    return (
        <Box gap="medium" width="full">
            <Box direction="row" gap="medium">
                <Card width="full">
                    <CardHeader pad="medium"><b>Basic Information</b></CardHeader>
                    <CardBody pad="medium">
                        <FormField name="name" label="Patient Name">
                            <TextInput
                                name="name"
                                value={demographics.name || ''}
                                onChange={(event) => handleChange('name', event.target.value)}
                            />
                        </FormField>
                        <Box direction="row" gap="medium">
                            <FormField name="month" label="Birth Month">
                                <Select
                                    options={monthOptions.map(option => option.label)}
                                    value={monthOptions.find(option => option.value === birthdateParts.month)?.label || ''}
                                    onChange={({ option }) => handleBirthdateChange('month', monthOptions.find(opt => opt.label === option).value)}
                                />
                            </FormField>
                            <FormField name="day" label="Birth Day">
                                <TextInput
                                    name="day"
                                    value={birthdateParts.day || ''}
                                    onChange={(event) => handleBirthdateChange('day', event.target.value)}
                                />
                            </FormField>
                            <FormField name="year" label="Birth Year">
                                <TextInput
                                    name="year"
                                    value={birthdateParts.year || ''}
                                    onChange={(event) => handleBirthdateChange('year', event.target.value)}
                                />
                            </FormField>
                            <FormField name="gender" label="Gender">
                                <Select
                                    options={['Male', 'Female']}
                                    value={demographics.gender || ''}
                                    onChange={({ option }) => handleChange('gender', option)}
                                />
                            </FormField>
                        </Box>

                        <Box direction="row" gap="medium">
                            <FormField name="language" label="Language">
                                <TextInput
                                    name="language"
                                    value={demographics.language || ''}
                                    onChange={(event) => handleChange('language', event.target.value)}
                                />
                            </FormField>
                            <FormField name="raceEthnicity" label="Race / Ethnicity">
                                <TextInput
                                    name="raceEthnicity"
                                    value={demographics.raceEthnicity || ''}
                                    onChange={(event) => handleChange('raceEthnicity', event.target.value)}
                                />
                            </FormField>
                            <FormField name="maritalStatus" label="Marital Status">
                                <TextInput
                                    name="maritalStatus"
                                    value={demographics.maritalStatus || ''}
                                    onChange={(event) => handleChange('maritalStatus', event.target.value)}
                                />
                            </FormField>
                        </Box>
                        <AliasField
                            aliases={demographics.aliases || []}
                            onUpdate={handleAliasesUpdate}
                        />
                    </CardBody>
                </Card>

                <Card width="full">
                    <CardHeader pad="medium"><b>Address Information</b></CardHeader>
                    <CardBody pad="medium">
                        <FormField name="street" label="Street Address">
                            <TextInput
                                name="street"
                                value={addressParts.street || ''}
                                onChange={(event) => handleAddressChange('street', event.target.value)}
                            />
                        </FormField>
                        <Box direction="row" gap="medium">
                            <FormField name="city" label="City">
                                <TextInput
                                    name="city"
                                    value={addressParts.city || ''}
                                    onChange={(event) => handleAddressChange('city', event.target.value)}
                                />
                            </FormField>
                            <FormField name="state" label="State">
                                <TextInput
                                    name="state"
                                    value={addressParts.state || ''}
                                    onChange={(event) => handleAddressChange('state', event.target.value)}
                                />
                            </FormField>
                            <FormField name="zip" label="Zip Code">
                                <TextInput
                                    name="zip"
                                    value={addressParts.zip || ''}
                                    onChange={(event) => handleAddressChange('zip', event.target.value)}
                                />
                            </FormField>
                        </Box>
                        <CommunicationField
                            communication={demographics.communication}
                            onUpdate={handleCommunicationUpdate}
                        />
                    </CardBody>
                </Card>
            </Box>
        </Box>
    );
};

export default PatientDemographics;

import React from "react";
import { Header, Box, Button, Text } from "grommet";
import { Logout } from "grommet-icons";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledHeader = styled(Header)`
  background-color: #000;
  border-bottom: none;
  padding: 0 2rem;
  box-shadow: none;
`;

const AppName = styled(Text)`
  font-weight: normal;
  color: #fff;
  letter-spacing: 1.2px;
`;

const LogoutButton = styled(Button)`
  border-radius: 50px;
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: #fff;
  font-weight: normal;
  border: none;
  transition: none;
  box-shadow: none;
  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

const NavButton = styled(Button)`
  border-radius: 50px;
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: #fff;
  font-weight: normal;
  border: none;
  transition: none;
  box-shadow: none;
  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

const AppBar = ({ setAuth }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        setAuth(false);
        localStorage.removeItem('isLoggedIn');
        navigate('/login');
    };

    const handlePatientAdmin = () => {
        navigate('/admin'); // Adjust the path as needed
    };

    const handleDashboard = () => {
        navigate('/'); // Adjust the path as needed
    };

    return (
        <StyledHeader justify="between">
            <Box direction="row" align="center" gap="medium" style={{ marginLeft: '1.8%' }}>
                <AppName>Agama</AppName>
                <NavButton label="Dashboard" onClick={handleDashboard} />
                <NavButton label="Patient Admin" onClick={handlePatientAdmin} />
            </Box>
            <LogoutButton icon={<Logout />} label="Logout" onClick={handleLogout} />
        </StyledHeader>
    );
};

export default AppBar;

import React, { useState } from 'react';
import { Box, Heading, Button, Layer } from 'grommet';
import { Add } from 'grommet-icons';
import PatientDataTable from './PatientDataTable';
import PatientDetails from './PatientDetails';
import EditPatient from './EditPatient'; // This will be used for adding a new patient

const AdminPage = () => {
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [showAddPatient, setShowAddPatient] = useState(false);
    const [editingPatient, setEditingPatient] = useState(null);

    const handlePatientSelect = (patient) => {
        setSelectedPatient(patient);
    };

    const handleAddPatient = () => {
        setEditingPatient(null);
        setShowAddPatient(true);
    };

    const handleEditPatient = (patient) => {
        setEditingPatient(patient);
        setShowAddPatient(true);
    };

    const handleCloseAddPatient = () => {
        setShowAddPatient(false);
    };

    return (
        <Box pad="medium">
            <Heading level="2">Admin Page</Heading>
            <Box direction="row" justify="between" align="center" margin={{ bottom: 'medium' }}>
                <Button
                    icon={<Add />}
                    label="Add New Patient"
                    onClick={handleAddPatient}
                    primary
                />
            </Box>
            <Box direction="row" gap="medium">
                <PatientDataTable onSelect={handlePatientSelect} onEdit={handleEditPatient} />
            </Box>
            {showAddPatient && (
                <Layer
                    position="center"
                    onClickOutside={handleCloseAddPatient}
                    onEsc={handleCloseAddPatient}
                >
                    <Box pad="medium" gap="small">
                        <Heading level="3" margin="none">{editingPatient ? 'Edit Patient' : 'Add New Patient'}</Heading>
                        <EditPatient
                            patient={editingPatient || {}}
                            onClose={handleCloseAddPatient}
                        />
                    </Box>
                </Layer>
            )}
        </Box>
    );
};

export default AdminPage;

import React from 'react';
import { StyledCard, InfoText } from './StyledComponents';

const PatientDetails = ({ patient }) => (
    <StyledCard>
        <InfoText><strong>Name:</strong> {patient.name}</InfoText>
        <InfoText><strong>Email:</strong> {patient.email}</InfoText>
        <InfoText><strong>Phone:</strong> {patient.phone}</InfoText>
        <InfoText><strong>Date of Birth:</strong> {patient.birthDate}</InfoText>
        <InfoText><strong>Address:</strong> {patient.address}</InfoText>
        <InfoText><strong>Insurance Provider:</strong> {patient.insuranceProvider}</InfoText>
        <InfoText><strong>Policy Number:</strong> {patient.policyNumber}</InfoText>
        <InfoText><strong>Policy Holder Name:</strong> {patient.policyHolderName}</InfoText>
    </StyledCard>
);

export default PatientDetails;

import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, Card, CardBody, CardHeader } from 'grommet';
import { LeftPanel as StyledLeftPanel, StyledButton, ToggleButton, StyledCard, InfoText } from './StyledComponents';
import PatientDetails from './PatientDetails';
import Chat from './ChatContainer';
import { CircleInformation } from 'grommet-icons';
import ProcessingOverlay from './ProcessingOverlay'; // Import the ProcessingOverlay component
import patientService from '../../services/patientService'; // Import patientService to fetch appointments
import ReactMarkdown from 'react-markdown';

const LeftPanel = ({
                       activeContent,
                       setActiveContent,
                       selectedPatient,
                       setSelectedPatient,
                       handleButtonClick,
                       handleToggleButtonClick,
                       dashboardCharts,
                       onDemandCharts,
                       chatHistory,
                       inputText,
                       setInputText,
                       sendMessage,
                       handleVoiceCommand,
                       listening,
                       loading,
                       setLoading
                   }) => {
    const [appointments, setAppointments] = useState([]);
    const [patients, setPatients] = useState([]);
    const [charts, setCharts] = useState([]);
    const [journalEntries, setJournalEntries] = useState([]);
    const [aiInsights, setAIInsights] = useState('');

    const appointmentChannel = new BroadcastChannel('appointment_channel');
    const infoChannel = new BroadcastChannel('info_channel');
    const journalChannel = new BroadcastChannel('journal_channel');

    const fetchAppointments = async () => {
        if (selectedPatient) {
            try {
                const response = await patientService.getAppointments(selectedPatient.id);
                const appointmentsData = response.map(item => item.Record);
                setAppointments(appointmentsData);
            } catch (error) {
                console.error('Error fetching appointments:', error);
            }
        }
    };

    const fetchPatients = async () => {
        setLoading(true);
        try {
            const patientData = await patientService.listPatientsWithCharts();
            const mappedPatients = patientData.map(patient => ({
                key: patient.Key,
                name: patient.Record.name,
                birthDate: patient.Record.birthDate,
                gender: patient.Record.gender,
                address: patient.Record.address,
                email: patient.Record.communication.email,
                phone: patient.Record.communication.home,
                charts: patient.Record.charts || [],
                ...patient.Record
            }));
            setPatients(mappedPatients);
            if (mappedPatients.length > 0) {
                setSelectedPatient(mappedPatients[0]);
                setCharts(mappedPatients[0].charts || []);
            }
        } catch (error) {
            console.error('Failed to fetch patients:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchJournalEntries = async (patientId) => {
        setLoading(true);
        try {
            const entries = await patientService.getJournalEntries(patientId);
            const formattedEntries = entries.map(entry => ({
                journalDate: entry.Record.journalDate || '',
                journalEntry: entry.Record.journalEntry || '',
                journalEntryId: entry.Record.journalEntryId
            }));
            setJournalEntries(formattedEntries);

            const allEntries = formattedEntries.map(entry => entry.journalEntry).join('\n');
            const response = await fetch('https://qft.agamanetworks.com/analyze-journal', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'mYetb4Ue52UqZfKFQxhG!',
                },
                body: JSON.stringify({ journalEntries: allEntries }),
            });

            if (response.ok) {
                const data = await response.json();
                setAIInsights(data.insights);
            } else {
                console.error('Error analyzing journal entries:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching journal entries:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPatients();
    }, []);

    useEffect(() => {
        if (patients.length > 0) {
            fetchJournalEntries(patients[0].key);
        }
    }, [patients]);

    useEffect(() => {
        if (selectedPatient) {
            fetchAppointments();
        }
    }, [selectedPatient]);

    useEffect(() => {
        const handleAppointmentUpdate = () => {
            if (selectedPatient) {
                fetchAppointments();
            }
        };

        appointmentChannel.onmessage = (message) => {
            if (message.data === 'appointment_updated') {
                handleAppointmentUpdate();
            }
        };

        return () => {
            appointmentChannel.close();
        };
    }, [selectedPatient]);

    useEffect(() => {
        const handleInfoUpdate = () => {
            if (selectedPatient) {
                fetchPatients();
            }
        };

        infoChannel.onmessage = (message) => {
            if (message.data === 'info_updated') {
                handleInfoUpdate();
            }
        };

        return () => {
            infoChannel.close();
        };
    }, [selectedPatient]);

    useEffect(() => {
        const handleJournalUpdate = () => {
            if (selectedPatient) {
                fetchJournalEntries(selectedPatient.key);
            }
        };

        journalChannel.onmessage = (message) => {
            if (message.data === 'journal_updated') {
                handleJournalUpdate();
            }
        };

        return () => {
            journalChannel.close();
        };
    }, [selectedPatient]);

    return (
        <StyledLeftPanel>
            <Box flex={false}>
                <StyledButton onClick={() => handleButtonClick('Vickie Moore')}>Vickie Doe</StyledButton>
                {activeContent === 'Vickie Moore' && selectedPatient && (
                    <PatientDetails patient={selectedPatient} />
                )}
                <StyledButton onClick={() => handleButtonClick('Portal Alerts')}>Portal Alerts</StyledButton>
                {activeContent === 'Portal Alerts' && (
                    <Box style={{ height: '50vh', overflowY: 'auto' }}>
                        <StyledCard>
                            <Heading level={3}>Appointments</Heading>
                            {appointments.length > 0 ? (
                                appointments.map((appointment, index) => (
                                    <Card key={index} pad="small" margin={{ bottom: 'small' }} background="light-1">
                                        <CardHeader pad={{ vertical: 'small' }}>
                                            <Text weight="bold" size="large">
                                                {appointment.appointmentDate}
                                            </Text>
                                        </CardHeader>
                                        <CardBody>
                                            <Text size="medium">
                                                Time: {appointment.appointmentTime}
                                            </Text>
                                            <Text size="medium">
                                                Type: {appointment.appointmentType}
                                            </Text>
                                            <Text size="medium">
                                                Physician: {appointment.requestedPhysician}
                                            </Text>
                                        </CardBody>
                                    </Card>
                                ))
                            ) : (
                                <Text>No appointments available.</Text>
                            )}
                        </StyledCard>
                    </Box>
                )}
                <StyledButton onClick={() => handleButtonClick('AI Suggested')}>A.I Suggested</StyledButton>
                {activeContent === 'AI Suggested' && (
                    <Box style={{ height: '50vh', overflowY: 'auto', color: "white" }}>
                        <Heading level={3}>Journal Summary</Heading>
                        {aiInsights ? (
                            <ReactMarkdown>{aiInsights}</ReactMarkdown>
                        ) : (
                            <Text>No journal entries available.</Text>
                        )}
                    </Box>
                )}
                <ToggleButton onClick={() => handleToggleButtonClick('dashboard')}>
                    Dashboard Charts
                    <CircleInformation className="icon" color={dashboardCharts ? '#006400' : 'status-unknown'} />
                </ToggleButton>
                <ToggleButton onClick={() => handleToggleButtonClick('onDemand')}>
                    On-Demand Charts
                    <CircleInformation className="icon" color={onDemandCharts ? '#006400' : 'status-unknown'} />
                </ToggleButton>
            </Box>
            <Box flex="grow" height="74vh" style={{ position: 'relative' }}>
                {loading && <ProcessingOverlay />}
                <Chat
                    chatHistory={chatHistory}
                    inputText={inputText}
                    setInputText={setInputText}
                    sendMessage={sendMessage}
                    handleVoiceCommand={handleVoiceCommand}
                    listening={listening}
                    loading={loading}
                />
            </Box>
        </StyledLeftPanel>
    );
};

export default LeftPanel;

// src/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, Form, FormField, TextInput, Text } from 'grommet';

const Login = ({ setAuth }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if user is already logged in
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn === 'true') {
            setAuth(true);
            navigate('/');
        }
    }, [setAuth, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            // Simulate a login request
            await new Promise((resolve) => setTimeout(resolve, 1000));
            if (username === 'agama-poc' && password === 'agama-poc') {
                setAuth(true);
                localStorage.setItem('isLoggedIn', 'true'); // Save auth state to local storage
                navigate('/');
            } else {
                setError('Invalid username or password');
            }
        } catch (err) {
            setError('Something went wrong. Please try again.');
        }
        setLoading(false);
    };

    return (
        <Box fill align="center" justify="center" background="light-2">
            <Box width="medium">
                <Form onSubmit={handleSubmit}>
                    <FormField label="Username" name="username" required>
                        <TextInput
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </FormField>
                    <FormField label="Password" name="password" required>
                        <TextInput
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </FormField>
                    {error && (
                        <Box pad={{ vertical: 'small' }}>
                            <Text color="status-critical">{error}</Text>
                        </Box>
                    )}
                    <Box direction="row" gap="medium" pad={{ top: 'medium' }}>
                        <Button type="submit" primary label={loading ? 'Logging in...' : 'Login'} disabled={loading} />
                    </Box>
                </Form>
            </Box>
        </Box>
    );
};

export default Login;

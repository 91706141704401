import React from 'react';
import { MainPanel as StyledMainPanel } from './StyledComponents';
import Chart from './ChartContainer';

const MainPanel = ({ selectedPatient, handleIframeLoad, dashboardCharts, onDemandCharts }) => (
    <StyledMainPanel>
        {selectedPatient?.charts.length > 0 && selectedPatient.charts.map((chart, index) => (
            <Chart
                key={index}
                chart={chart}
                index={index}
                handleIframeLoad={handleIframeLoad}
                dashboardCharts={dashboardCharts}
                onDemandCharts={onDemandCharts}
            />
        ))}
    </StyledMainPanel>
);

export default MainPanel;

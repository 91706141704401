import React from 'react';
import { Box } from 'grommet';
import SocialHistory from './SocialHistory';
import LastFiledVitalSigns from './LastFiledVitalSigns';

const SocialHistoryVitalSigns = ({ socialHistory, vitalSigns, onSocialHistoryUpdate, onVitalSignsUpdate }) => {
    return (
        <Box gap="medium">
            <Box>
                <SocialHistory socialHistory={socialHistory} onUpdate={onSocialHistoryUpdate} />
            </Box>
            <Box>
                <LastFiledVitalSigns vitals={vitalSigns} onUpdate={onVitalSignsUpdate} />
            </Box>
        </Box>
    );
};

export default SocialHistoryVitalSigns;

import React from 'react';
import { Box } from 'grommet';
import Allergies from './Allergies';
import Medications from './Medications';

const AllergiesMedications = ({ allergies, medications, onAllergiesUpdate, onMedicationsUpdate }) => {
    return (
        <Box direction="row" gap="large">
            <Box width="50%">
                <Allergies allergies={allergies} onUpdate={onAllergiesUpdate} />
            </Box>
            <Box width="50%">
                <Medications medications={medications} onUpdate={onMedicationsUpdate} />
            </Box>
        </Box>
    );
};

export default AllergiesMedications;

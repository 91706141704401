import styled from 'styled-components';
import { Box, Button, TextInput, Text } from 'grommet';

export const Container = styled(Box)`
  height: 96.9vh;
  margin: 0;
  overflow: hidden;
  background-color: #000000;
  color: black;
  font-family: Arial, sans-serif;
`;

export const LeftPanel = styled(Box)`
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const MainPanel = styled(Box)`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;

export const ChatContainer = styled(Box)`
  min-height: fit-content;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 2px solid #444;
  color: white;
  background-color: #000;
`;

export const StyledButton = styled(Button)`
  margin-bottom: 10px;
  padding: 10px;
  border: none;
  cursor: pointer;
  background-color: #00b2cc;
  color: #fff;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 1em;
  border-radius: 4px;
  box-shadow: none;

  &:hover {
    background-color: rgba(0, 178, 204, 0.8);
  }
`;

export const ToggleButton = styled(Button)`
  margin-bottom: 10px;
  padding: 10px;
  border: none;
  cursor: pointer;
  background-color: #00b2cc;
  color: #fff;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 1em;
  border-radius: 4px;
  box-shadow: none;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(0, 178, 204, 0.8);
  }

  .icon {
    margin-left: auto;
    margin-right: 0;
  }
`;

export const StyledCard = styled(Box)`
  background-color: #333;
  border: 1px solid #444;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #e0e0e0;
`;

export const InfoText = styled(Text)`
  color: #fff;

  strong {
    color: #00b2cc;
  }
`;

export const StyledTextInput = styled(TextInput)`
  flex: 1;
  margin-right: 8px;
  border: none;
  padding: 10px;
  background-color: #333;
  color: white;
  border-radius: 4px;
  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 178, 204, 0.8);
  }
`;

export const ChartContainer = styled(Box)`
  width: 100%;
  margin-bottom: 15px;
  border: 4px solid #444;
  border-radius: 8px;
  min-height: 86vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Iframe = styled.iframe`
  width: 100%;
  border: none;
`;

import React from 'react';
import { Box, FormField, TextInput, TextArea, Card, CardBody, CardHeader, Heading } from 'grommet';

const LastFiledVitalSigns = ({ vitals = {}, onUpdate }) => {
    const defaultVitals = {
        bloodPressure: '',
        pulse: '',
        temperature: '',
        respiratoryRate: '',
        oxygenSaturation: '',
        inhaledOxygenConcentration: '',
        weight: '',
        height: '',
        bmi: '',
        comments: ''
    };

    const vitalsData = { ...defaultVitals, ...vitals };

    const handleChange = (field, value) => {
        onUpdate({
            ...vitalsData,
            [field]: value,
        });
    };

    return (
        <Box gap="medium" direction="row" wrap={true}>
            <Card pad="medium" background="light-1" width="medium" margin="small">
                <CardHeader>
                    <Heading level="3" margin="none">Basic Vitals</Heading>
                </CardHeader>
                <CardBody>
                    <FormField name="bloodPressure" label="Blood Pressure">
                        <TextInput
                            name="bloodPressure"
                            value={vitalsData.bloodPressure}
                            onChange={(event) => handleChange('bloodPressure', event.target.value)}
                        />
                    </FormField>
                    <FormField name="pulse" label="Pulse">
                        <TextInput
                            name="pulse"
                            value={vitalsData.pulse}
                            onChange={(event) => handleChange('pulse', event.target.value)}
                        />
                    </FormField>
                    <FormField name="temperature" label="Temperature">
                        <TextInput
                            name="temperature"
                            value={vitalsData.temperature}
                            onChange={(event) => handleChange('temperature', event.target.value)}
                        />
                    </FormField>
                    <FormField name="respiratoryRate" label="Respiratory Rate">
                        <TextInput
                            name="respiratoryRate"
                            value={vitalsData.respiratoryRate}
                            onChange={(event) => handleChange('respiratoryRate', event.target.value)}
                        />
                    </FormField>
                </CardBody>
            </Card>

            <Card pad="medium" background="light-1" width="medium" margin="small">
                <CardHeader>
                    <Heading level="3" margin="none">Oxygen & Weight</Heading>
                </CardHeader>
                <CardBody>
                    <FormField name="oxygenSaturation" label="Oxygen Saturation">
                        <TextInput
                            name="oxygenSaturation"
                            value={vitalsData.oxygenSaturation}
                            onChange={(event) => handleChange('oxygenSaturation', event.target.value)}
                        />
                    </FormField>
                    <FormField name="inhaledOxygenConcentration" label="Inhaled Oxygen Concentration">
                        <TextInput
                            name="inhaledOxygenConcentration"
                            value={vitalsData.inhaledOxygenConcentration}
                            onChange={(event) => handleChange('inhaledOxygenConcentration', event.target.value)}
                        />
                    </FormField>
                    <FormField name="weight" label="Weight">
                        <TextInput
                            name="weight"
                            value={vitalsData.weight}
                            onChange={(event) => handleChange('weight', event.target.value)}
                        />
                    </FormField>
                </CardBody>
            </Card>

            <Card pad="medium" background="light-1" width="medium" margin="small">
                <CardHeader>
                    <Heading level="3" margin="none">Height & BMI</Heading>
                </CardHeader>
                <CardBody>
                    <FormField name="height" label="Height">
                        <TextInput
                            name="height"
                            value={vitalsData.height}
                            onChange={(event) => handleChange('height', event.target.value)}
                        />
                    </FormField>
                    <FormField name="bmi" label="Body Mass Index">
                        <TextInput
                            name="bmi"
                            value={vitalsData.bmi}
                            onChange={(event) => handleChange('bmi', event.target.value)}
                        />
                    </FormField>
                </CardBody>
            </Card>

            <Card pad="medium" background="light-1" width="medium" margin="small">
                <CardHeader>
                    <Heading level="3" margin="none">Comments</Heading>
                </CardHeader>
                <CardBody>
                    <FormField name="comments" label="Comments">
                        <TextArea
                            name="comments"
                            value={vitalsData.comments}
                            onChange={(event) => handleChange('comments', event.target.value)}
                        />
                    </FormField>
                </CardBody>
            </Card>
        </Box>
    );
};

export default LastFiledVitalSigns;

import React, { useState } from 'react';
import { Box, TextInput, Button, DataTable } from 'grommet';
import { Add, Edit, Trash, Save } from 'grommet-icons';

const Medications = ({ medications, onUpdate }) => {
    const initialMedication = { name: '', dosage: '', indication: '', startDate: '' };
    const [newMedication, setNewMedication] = useState(initialMedication);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingMedication, setEditingMedication] = useState(initialMedication);

    const handleAdd = () => {
        if (newMedication.name.trim() === '') return;
        onUpdate([...medications, newMedication]);
        setNewMedication(initialMedication);
    };

    const handleEdit = (index) => {
        setEditingIndex(index);
        setEditingMedication(medications[index]);
    };

    const handleSaveEdit = (index) => {
        const updatedMedications = [...medications];
        updatedMedications[index] = editingMedication;
        onUpdate(updatedMedications);
        setEditingIndex(null);
        setEditingMedication(initialMedication);
    };

    const handleDelete = (index) => {
        const updatedMedications = medications.filter((_, i) => i !== index);
        onUpdate(updatedMedications);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewMedication((prev) => ({ ...prev, [name]: value }));
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditingMedication((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <Box height="large" overflow="auto">
            <Box direction="column" gap="small" align="center" pad={{ vertical: 'large' }}>
                <TextInput
                    placeholder="Name"
                    name="name"
                    value={newMedication.name}
                    onChange={handleChange}
                />
                <TextInput
                    placeholder="Dosage"
                    name="dosage"
                    value={newMedication.dosage}
                    onChange={handleChange}
                />
                <TextInput
                    placeholder="Indication"
                    name="indication"
                    value={newMedication.indication}
                    onChange={handleChange}
                />
                <TextInput
                    placeholder="Start Date"
                    name="startDate"
                    value={newMedication.startDate}
                    onChange={handleChange}
                />
                <Button icon={<Add />} onClick={handleAdd} />
            </Box>
            <DataTable
                columns={[
                    {
                        property: 'name',
                        header: 'Name',
                        primary: true,
                        render: (data) =>
                            editingIndex === data.index ? (
                                <TextInput
                                    name="name"
                                    value={editingMedication.name}
                                    onChange={handleEditChange}
                                />
                            ) : (
                                data.name
                            ),
                    },
                    {
                        property: 'dosage',
                        header: 'Dosage',
                        render: (data) =>
                            editingIndex === data.index ? (
                                <TextInput
                                    name="dosage"
                                    value={editingMedication.dosage}
                                    onChange={handleEditChange}
                                />
                            ) : (
                                data.dosage
                            ),
                    },
                    {
                        property: 'indication',
                        header: 'Indication',
                        render: (data) =>
                            editingIndex === data.index ? (
                                <TextInput
                                    name="indication"
                                    value={editingMedication.indication}
                                    onChange={handleEditChange}
                                />
                            ) : (
                                data.indication
                            ),
                    },
                    {
                        property: 'startDate',
                        header: 'Start Date',
                        render: (data) =>
                            editingIndex === data.index ? (
                                <TextInput
                                    name="startDate"
                                    value={editingMedication.startDate}
                                    onChange={handleEditChange}
                                />
                            ) : (
                                data.startDate
                            ),
                    },
                    {
                        property: 'actions',
                        header: 'Actions',
                        render: (data) =>
                            editingIndex === data.index ? (
                                <Button icon={<Save />} onClick={() => handleSaveEdit(data.index)} />
                            ) : (
                                <Box direction="row" gap="small">
                                    <Button icon={<Edit />} onClick={() => handleEdit(data.index)} />
                                    <Button icon={<Trash />} onClick={() => handleDelete(data.index)} />
                                </Box>
                            ),
                    },
                ]}
                data={medications.map((medication, index) => ({ ...medication, index }))}
            />
        </Box>
    );
};

export default Medications;

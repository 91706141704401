import React, { useState } from 'react';
import { Grommet } from 'grommet';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import AppBar from './components/AppBar';
import Dashboard from './pages/Dashboard';
import Account from './pages/Account';
import NotFound from './pages/NotFound';
import Login from './components/Login';
import AdminPage from './components/AdminPage';
import AgamaPatientPortal from './pages/AgamaPatientPortal';
import { theme } from './styles/theme';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const customTheme = {
    global: {
        colors: {
            background: '#000000', // Set the background color to black
        },
    },
};

function App() {
    const [dark, setDark] = useState(false);
    const [isAuth, setIsAuth] = useState(false); // Authentication state
    const location = useLocation(); // Get the current location

    return (
        <Grommet theme={{ ...theme, ...customTheme }} full themeMode={dark ? 'dark' : 'light'}>
            {/* Conditionally render AppBar */}
            {location.pathname !== '/patient-portal' && <AppBar dark={dark} setDark={setDark} setAuth={setIsAuth} />}
            <Routes>
                <Route path="/login" element={<Login setAuth={setIsAuth} />} />
                <Route path="/" element={isAuth ? <Dashboard /> : <Navigate to="/login" />} />
                <Route path="/Admin" element={isAuth ? <AdminPage /> : <Navigate to="/login" />} />
                <Route path="/patient-portal" element={<AgamaPatientPortal />} /> {/* Add the patient portal route */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Grommet>
    );
}

function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default AppWrapper;

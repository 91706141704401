import React, { useRef, useEffect } from 'react';
import { Box, Button } from 'grommet';
import { Microphone, Send } from 'grommet-icons';
import { ChatContainer as StyledChatContainer, StyledTextInput } from './StyledComponents';
import Message from '../Message';
import ProcessingOverlay from './ProcessingOverlay';

const Chat = ({ chatHistory, inputText, setInputText, sendMessage, handleVoiceCommand, listening, loading }) => {
    const messageListRef = useRef(null);

    useEffect(() => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    }, [chatHistory]);

    return (
        <StyledChatContainer style={{ position: 'relative', display: 'flex', flexDirection: 'column', height: '100%' }}>
            {loading && <ProcessingOverlay />}
            <Box flex overflow="auto" pad="small" style={{ flexGrow: 1 }}>
                <Box ref={messageListRef} style={{ minHeight: 'fit-content' }}>
                    {chatHistory.map((msg, index) => (
                        <Message key={index} message={msg} />
                    ))}
                </Box>
            </Box>
            <Box direction="row" pad="small" border={{ side: 'top', color: '#444' }} style={{ flexShrink: 0 }}>
                <Button
                    icon={<Microphone color={listening ? 'green' : undefined} />}
                    onClick={handleVoiceCommand}
                />
                <StyledTextInput
                    placeholder="Type your message here..."
                    value={inputText}
                    onChange={(event) => setInputText(event.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            sendMessage(inputText);
                        }
                    }}
                />
                <Button icon={<Send />} onClick={() => sendMessage(inputText)} />
            </Box>
        </StyledChatContainer>
    );
};

export default Chat;

import React, { useState, useEffect } from 'react';
import {Box, DataTable, Button, Layer, Text, Heading} from 'grommet';
import { Edit, Trash } from 'grommet-icons';
import patientService from "../services/patientService";

const PatientDataTable = ({ onSelect, onEdit }) => {
    const [patients, setPatients] = useState([]);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [patientToDelete, setPatientToDelete] = useState(null);

    useEffect(() => {
        const fetchPatients = async () => {
            try {
                const patientData = await patientService.listPatientsWithCharts();
                // Map the patient data to a simpler format
                const mappedPatients = patientData.map(patient => ({
                    key: patient.Key,
                    name: patient.Record.name,
                    birthDate: patient.Record.birthDate,
                    gender: patient.Record.gender,
                    address: patient.Record.address,
                    ...patient.Record
                }));
                setPatients(mappedPatients);
            } catch (error) {
                console.error('Failed to fetch patients:', error);
            }
        };
        fetchPatients();
    }, []);

    const handleDelete = async () => {
        if (patientToDelete) {
            try {
                await patientService.deletePatient(patientToDelete.key);
                setPatients(patients.filter(patient => patient.key !== patientToDelete.key));
                setShowConfirmDelete(false);
                setPatientToDelete(null);
            } catch (error) {
                console.error('Failed to delete patient:', error);
            }
        }
    };

    return (
        <Box pad="medium" background="light-2" round="small" elevation="small">
            <Box direction="row" justify="between" align="center">
                <Heading level="3" margin={{ bottom: 'small' }}>Patient List</Heading>
            </Box>
            <DataTable
                columns={[
                    {
                        property: 'name',
                        header: 'Name',
                        primary: true,
                        render: datum => <strong>{datum.name}</strong>
                    },
                    {
                        property: 'birthDate',
                        header: 'Birth Date'
                    },
                    {
                        property: 'gender',
                        header: 'Gender'
                    },
                    {
                        property: 'address',
                        header: 'Address',
                        size: 'medium'
                    },
                    {
                        property: 'edit',
                        header: '',
                        render: datum => (
                            <Box direction="row" gap="small">
                                <Button
                                    icon={<Edit />}
                                    onClick={() => onEdit(datum)}
                                />
                                <Button
                                    icon={<Trash />}
                                    onClick={() => {
                                        setPatientToDelete(datum);
                                        setShowConfirmDelete(true);
                                    }}
                                />
                            </Box>
                        ),
                        sortable: false
                    }
                ]}
                data={patients}
                onClickRow={({ datum }) => onSelect(datum)}
                pad="small"
                background={{
                    header: 'brand',
                    body: ['light-1', 'light-3']
                }}
                border={{ color: 'light-4', size: 'xsmall' }}
            />
            {showConfirmDelete && (
                <Layer
                    position="center"
                    onClickOutside={() => setShowConfirmDelete(false)}
                    onEsc={() => setShowConfirmDelete(false)}
                >
                    <Box pad="medium" gap="small">
                        <Text>Are you sure you want to delete this patient?</Text>
                        <Box direction="row" gap="medium" justify="center">
                            <Button label="Yes" onClick={handleDelete} />
                            <Button label="No" onClick={() => setShowConfirmDelete(false)} />
                        </Box>
                    </Box>
                </Layer>
            )}
        </Box>
    );
};

export default PatientDataTable;

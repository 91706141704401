import React, { useState, useEffect } from 'react';
import { Box, Button, Form, Tabs, Tab, FileInput, Layer, Text, Spinner } from 'grommet';
import { Notification, StatusGood } from 'grommet-icons';
import styled from 'styled-components';
import patientService from '../services/patientService';
import PatientDemographics from './PatientDemographics';
import AllergiesMedications from './AllergiesMedications';
import ProblemsProcedures from './ProblemsProcedures';
import SocialHistoryVitalSigns from './SocialHistoryVitalSigns';
import PatientCharts from './PatientCharts';
import { defaultPatientData } from '../data/staticData';
import { formatToJsonString } from '../utils/chartUtils';

// Styled component for tabs
const StyledTabs = styled(Tabs)`
  button {
    color: black;
    font-weight: normal;
    border-bottom: none;
    background: transparent;
    box-shadow: none;
    padding: 0;
    margin-right: 10px;

    &:focus {
      outline: none;
    }
  }

  button[aria-selected='true'] {
    color: black;
    font-weight: bold;
    border-bottom: 2px solid black;
    background: transparent;
  }

  button:hover {
    color: blue;
  }
`;

const EditPatient = ({ patient = defaultPatientData, onClose }) => {
    const initialFormValues = {
        gender: '',
        birthDate: '',
        address: '',
        name: '',
        aliases: [],
        communication: [],
        language: '',
        raceEthnicity: '',
        maritalStatus: '',
        allergies: [],
        medications: [],
        problems: [],
        procedures: [],
        socialHistory: {
            smokingTobacco: '',
            smokelessTobacco: '',
            tobaccoCessation: '',
            sexAssignedAtBirth: '',
            genderIdentity: '',
            sexualOrientation: '',
        },
        vitals: {
            bloodPressure: '',
            pulse: '',
            temperature: '',
            respiratoryRate: '',
            oxygenSaturation: '',
            inhaledOxygenConcentration: '',
            weight: '',
            height: '',
            bmi: '',
            comments: ''
        },
        charts: []
    };

    const [formValues, setFormValues] = useState(initialFormValues);
    const [activeTab, setActiveTab] = useState(0);
    const [showUpload, setShowUpload] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const isEmptyObject = Object.keys(patient).length === 0;
        const data = isEmptyObject ? defaultPatientData : { ...defaultPatientData, ...patient };

        setFormValues({
            id: patient.key || null,
            gender: data.gender || '',
            birthDate: data.birthDate || '',
            address: data.address || '',
            name: data.name || '',
            aliases: Array.isArray(data.aliases) ? data.aliases : data.aliases ? [data.aliases] : [],
            communication: data.communication || [],
            language: data.language || '',
            raceEthnicity: data.raceEthnicity || '',
            maritalStatus: data.maritalStatus || '',
            allergies: data.allergies || [],
            medications: data.medications || [],
            problems: data.problems || [],
            procedures: data.procedures || [],
            socialHistory: data.socialHistory || {
                smokingTobacco: '',
                smokelessTobacco: '',
                tobaccoCessation: '',
                sexAssignedAtBirth: '',
                genderIdentity: '',
                sexualOrientation: '',
            },
            vitals: data.vitals || {
                bloodPressure: '',
                pulse: '',
                temperature: '',
                respiratoryRate: '',
                oxygenSaturation: '',
                inhaledOxygenConcentration: '',
                weight: '',
                height: '',
                bmi: '',
                comments: ''
            },
            charts: data.charts || []
        });
    }, [patient]);

    const handleUpdate = (updatedValues) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            ...updatedValues,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        // Ensure formValues.communication is always in the desired format (array of objects)
        const communicationArray = Array.isArray(formValues.communication)
            ? formValues.communication
            : Object.entries(formValues.communication || {}).map(([type, value]) => ({ type, value }));

        const updatedFormValues = {
            ...formValues,
            communication: communicationArray.reduce((acc, { type, value }) => {
                acc[type] = value;
                return acc;
            }, {}),
        };

        try {
            let patientId;
            console.log(patient);

            // Create a deep copy of the updatedFormValues to avoid mutating the state directly
            const sanitizedFormValues = { ...updatedFormValues };

            // Remove the charts field
            delete sanitizedFormValues.charts;

            if (patient?.key) {
                await patientService.updatePatient(patient.key, sanitizedFormValues);
                patientId = patient.key;
            } else {
                const newPatient = await patientService.createPatient(sanitizedFormValues);
                patientId = newPatient.patientId;
            }

            setFormValues((prevValues) => ({
                ...prevValues,
                id: patientId,
            }));

            onClose();
        } catch (error) {
            console.error("Error submitting form:", error);
            // Optionally, handle the error by displaying a message to the user
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setUploadedFile(file);
    };

    const handleUpload = async () => {
        if (!uploadedFile) return;

        const formData = new FormData();
        formData.append('file', uploadedFile);

        try {
            const response = await fetch('https://qft.agamanetworks.com/process-file', {
                method: 'POST',
                headers: {
                    'Authorization': 'mYetb4Ue52UqZfKFQxhG!',
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                let patientDataString = data.patientData;

                // Remove any trailing semicolon
                if (patientDataString.endsWith(';')) {
                    patientDataString = patientDataString.slice(0, -1);
                }

                patientDataString = formatToJsonString(patientDataString);
                console.log(patientDataString);
                // Safely evaluate the string into an object
                const patientData = eval(`(${patientDataString})`);

                // Update form values with the parsed patient data
                setFormValues((prevValues) => ({
                    ...prevValues,
                    ...patientData,
                }));

                setNotificationMessage(`${patientData.name} has been uploaded and parsed successfully!`);
                setShowNotification(true);
                setShowUpload(false);
            } else {
                console.error('Upload failed');
                setNotificationMessage('Upload failed');
                setShowNotification(true);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setNotificationMessage('Error uploading file');
            setShowNotification(true);
        }
    };

    return (
        <Box pad="medium" width="80vw">
            {isLoading && (
                <Layer>
                    <Box pad="large" align="center" gap="small">
                        <Spinner />
                        <Text>Processing...</Text>
                    </Box>
                </Layer>
            )}
            <Box align="center" margin={{ bottom: 'medium' }}>
                <Button label="Upload Document" onClick={() => setShowUpload(true)} />
            </Box>
            {showUpload && (
                <Layer
                    onEsc={() => setShowUpload(false)}
                    onClickOutside={() => setShowUpload(false)}
                >
                    <Box pad="medium" gap="small">
                        <FileInput
                            name="file"
                            onChange={handleFileChange}
                        />
                        <Button
                            label="Upload"
                            onClick={handleUpload}
                        />
                    </Box>
                </Layer>
            )}
            {showNotification && (
                <Layer
                    position="top"
                    modal={false}
                    margin={{ vertical: 'medium', horizontal: 'small' }}
                    onEsc={() => setShowNotification(false)}
                    responsive={false}
                    plain
                >
                    <Box
                        align="center"
                        direction="row"
                        gap="small"
                        justify="between"
                        round="small"
                        elevation="medium"
                        pad={{ vertical: 'xsmall', horizontal: 'small' }}
                        background="status-ok"
                    >
                        <Box align="center" direction="row" gap="xsmall">
                            <StatusGood />
                            <Text>{notificationMessage}</Text>
                        </Box>
                        <Button
                            icon={<Notification />}
                            onClick={() => setShowNotification(false)}
                            plain
                        />
                    </Box>
                </Layer>
            )}
            <Form onSubmit={handleSubmit}>
                <StyledTabs activeIndex={activeTab} onActive={(index) => setActiveTab(index)}>
                    <Tab title="Demographics">
                        <Box pad={{ vertical: 'large' }}>
                            <PatientDemographics demographics={formValues} onUpdate={handleUpdate} />
                        </Box>
                    </Tab>
                    <Tab title="Allergies & Medications">
                        <Box pad={{ vertical: 'large' }}>
                            <AllergiesMedications
                                allergies={formValues.allergies}
                                medications={formValues.medications}
                                onAllergiesUpdate={(updatedAllergies) => {
                                    handleUpdate({ allergies: updatedAllergies });
                                    setActiveTab(1); // Ensure tab stays active
                                }}
                                onMedicationsUpdate={(updatedMedications) => {
                                    handleUpdate({ medications: updatedMedications });
                                    setActiveTab(1); // Ensure tab stays active
                                }}
                            />
                        </Box>
                    </Tab>
                    <Tab title="Problems & Procedures">
                        <Box pad={{ vertical: 'large' }}>
                            <ProblemsProcedures
                                problems={formValues.problems}
                                procedures={formValues.procedures}
                                onProblemsUpdate={(updatedProblems) => {
                                    handleUpdate({ problems: updatedProblems });
                                    setActiveTab(2); // Ensure tab stays active
                                }}
                                onProceduresUpdate={(updatedProcedures) => {
                                    handleUpdate({ procedures: updatedProcedures });
                                    setActiveTab(2); // Ensure tab stays active
                                }}
                            />
                        </Box>
                    </Tab>
                    <Tab title="Social History & Vital Signs">
                        <Box pad={{ vertical: 'large' }}>
                            <SocialHistoryVitalSigns
                                socialHistory={formValues.socialHistory}
                                vitalSigns={formValues.vitals}
                                onSocialHistoryUpdate={(updatedSocialHistory) => {
                                    handleUpdate({ socialHistory: updatedSocialHistory });
                                    setActiveTab(3); // Ensure tab stays active
                                }}
                                onVitalSignsUpdate={(updatedVitalSigns) => {
                                    handleUpdate({ vitals: updatedVitalSigns });
                                    setActiveTab(3); // Ensure tab stays active
                                }}
                            />
                        </Box>
                    </Tab>
                    <Tab title="Patient Charts" disabled={!formValues.id}>
                        <Box pad={{ vertical: 'large' }}>
                            <PatientCharts
                                patientId={formValues.id}
                                charts={formValues.charts}
                                onChartsUpdate={(updatedCharts) => {
                                    handleUpdate({ charts: updatedCharts });
                                    setActiveTab(4); // Ensure tab stays active
                                }}
                            />
                        </Box>
                    </Tab>
                </StyledTabs>
                <Box direction="row" gap="medium" margin={{ top: 'medium' }}>
                    <Button type="submit" label="Save" primary />
                    <Button type="button" label="Cancel" onClick={onClose} />
                </Box>
            </Form>
        </Box>
    );
};

export default EditPatient;

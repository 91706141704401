import React from 'react';
import { Box, FormField, TextInput, Card, CardBody, CardHeader, Heading } from 'grommet';

const SocialHistory = ({ socialHistory, onUpdate }) => {

    const handleChange = (field, value) => {
        onUpdate({
            ...socialHistory,
            [field]: value,
        });
    };

    return (
        <Box gap="medium" direction="row" wrap={true}>
            <Card pad="medium" background="light-1" width="medium" margin="small">
                <CardHeader>
                    <Heading level="3" margin="none">Tobacco Use</Heading>
                </CardHeader>
                <CardBody>
                    <FormField name="smokingTobacco" label="Smoking Tobacco">
                        <TextInput
                            name="smokingTobacco"
                            value={socialHistory.smokingTobacco}
                            onChange={(event) => handleChange('smokingTobacco', event.target.value)}
                        />
                    </FormField>
                    <FormField name="smokelessTobacco" label="Smokeless Tobacco">
                        <TextInput
                            name="smokelessTobacco"
                            value={socialHistory.smokelessTobacco}
                            onChange={(event) => handleChange('smokelessTobacco', event.target.value)}
                        />
                    </FormField>
                    <FormField name="tobaccoCessation" label="Tobacco Cessation">
                        <TextInput
                            name="tobaccoCessation"
                            value={socialHistory.tobaccoCessation}
                            onChange={(event) => handleChange('tobaccoCessation', event.target.value)}
                        />
                    </FormField>
                </CardBody>
            </Card>

            <Card pad="medium" background="light-1" width="medium" margin="small">
                <CardHeader>
                    <Heading level="3" margin="none">Sex and Gender Information</Heading>
                </CardHeader>
                <CardBody>
                    <FormField name="sexAssignedAtBirth" label="Sex Assigned at Birth">
                        <TextInput
                            name="sexAssignedAtBirth"
                            value={socialHistory.sexAssignedAtBirth}
                            onChange={(event) => handleChange('sexAssignedAtBirth', event.target.value)}
                        />
                    </FormField>
                    <FormField name="genderIdentity" label="Gender Identity">
                        <TextInput
                            name="genderIdentity"
                            value={socialHistory.genderIdentity}
                            onChange={(event) => handleChange('genderIdentity', event.target.value)}
                        />
                    </FormField>
                    <FormField name="sexualOrientation" label="Sexual Orientation">
                        <TextInput
                            name="sexualOrientation"
                            value={socialHistory.sexualOrientation}
                            onChange={(event) => handleChange('sexualOrientation', event.target.value)}
                        />
                    </FormField>
                </CardBody>
            </Card>
        </Box>
    );
};

export default SocialHistory;
